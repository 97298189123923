<template>
  <div class="dp-f fw-w box" v-loading="loading" :element-loading-text="config.loading.text"
      :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
    <div class="w-240 h-281 bg-ED pos-r cu-p mr-25 mb-25" v-for="item in state.Notice" :key="item.id">
      <el-image class="img mt-10 ml-10" :src="item.sysFile.fileUrl" />
      <div class="pos-a bottom w100 dp-f jc-c pt-14 pb-14 fs-14 contractName">
        {{item.templateTitle}}
      </div>
      <div class="mask w100 pos-a dp-f" >
        <div class="dp-f fd-c jc-c w100 h100 ai-c" >
          <el-image class="w-26 h-26" :src="require('@/assets/img/template/eye.png')"/>
          <span class="c-fff mt-5" @click="router_push_name('create_page', { id: item.id }, undefined, true)">预览/编辑</span>
          <a class="c-fff mt-26">使用模板</a>
          <span class="c-fff mt-15" @click="deteleTem(item.id)">删除</span>
        </div>
      </div>
    </div>
    <div class="dp-f jc-c w100">
        <el-pagination class="dp-f jc-c " @current-change="handleCurrentChange" background
      layout="prev, pager, next,slot" :current-page="currentPage" :total="totalPage" :page-size="10">
    </el-pagination>
    </div>
  </div>
</template>
<script setup>
import { ref,reactive } from 'vue'
import { router_push_name } from "@/utils/server/router.js"
import qs from "qs";
import { httpToken } from "@/utils/request";
import { handleCofirm, handleMessage } from '@/utils/server/confirm';
const props = defineProps({
  input: {
    default: '',
  },
});
const currentPage = ref(1); //当前页数
const totalPage = ref(0); //总条数
const loading =ref(false)
const state=reactive({
  Notice:[]
})
// /templateCon/list
let getdatalb = (val) => {
  loading.value = true;
  let json = [];
  if(props.input){
    json.push({ column: "template_title", type: 'like',value:props.input })
  }
  currentPage.value = val;
  httpToken({
    method: "post",
    url: "/admin/templateCon/list",
    data: qs.stringify({
      page: val,
      rows: 10,
      paramData: JSON.stringify(json),
    }),
  })
    .then((res) => {
      // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
      if (val !== res.data.current) {
        getdatalb(res.data.current);
        return;
      }
      totalPage.value = res.data.total;
      // totalofpages.value = res.data.pages;
      state.Notice = res.data.records;
      loading.value = false;
    })
    .catch(() => {
      loading.value = false;
    });
};
getdatalb(currentPage.value)
const handleCurrentChange = (e) => {
  currentPage.value = e;
  getdatalb(e);
};

const deteleTem=(id)=>{
  let json=[id]
  handleCofirm('你确定删除该模版吗').then(res=>{
    httpToken({
      method:'post',
      url:'/admin/templateCon/del',
      data:qs.stringify({ids:json.join(',')})
    }).then(res=>{
      handleMessage('删除成功','success')
      getdatalb(currentPage.value)
    })

  })
}
defineExpose({
  getdatalb
});
</script>


<style lang="scss" scoped>
@import "@/styles/color/value.scss";
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置
.bg-ED {
  background-color: #ededed;
  .contractName{
    color: $color-button;
  }
  .img {
    width: calc(100% - 20px);
    height: calc(100% - 10px);
  }
  .mask {
    display: none;
  }
  &:hover {
    .mask {
      display: block;
      height: calc(100% - 47px);
      background: rgba(0, 0, 0, 0.3);
      top: 0;
    }
  }
}
.bottom {
  background: $active-theme;
  bottom: 0;
}
.box{
  min-height: 720px;

}
</style>