<template>
  <div class="w100 bg-fff wtm_height2 pt-22 pl-22 autobox">
    <div class="dp-f jc-sb">
      <div class="dp-f">
        <!-- v-permission="'operation_myTemplate:add'" -->
        <!-- <el-dropdown trigger="hover" @visible-change="visibleChange" >
          <span class="el-dropdown-link dp-f ai-c">
            创建模版
            <img src="@/assets/img/template/hide.png" class="w-16 h-16">
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <el-upload :accept="doc_accept" :on-remove="handleRemove" :auto-upload="false" :on-change="fileupload">
                  本地上传(word模板)
                </el-upload>
              </el-dropdown-item>
              <el-upload :accept="pdf_accept" :on-remove="handleRemove" :auto-upload="false" :on-change="fileupload">
                <el-dropdown-item>本地上传(pdf模板)</el-dropdown-item>
              </el-upload>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->
        <div class="sinputbox">
          <el-input v-model="state.searchKey.input" placeholder="搜索模版名称">
            <template #append>
              <div class="h100 dp-fc">
                <span class="icon iconfont icon-sousuo cu-p" @click="unref(templateRef).getdatalb(1)"></span>
              </div>
            </template>
          </el-input>
        </div>
      </div>
      <oabutton title="创建模版" width=116 height=38 class="mr-22" style="border-radius: 5px;" @click="createtem"></oabutton>
    </div>
    <template-list class="mt-32 ml-30" ref="templateRef" :input="state.searchKey.input"></template-list>
  </div>
</template>

<script setup>
import { ref, computed, unref, reactive } from "vue";
import { useStore } from "vuex"
import { doc_accept, pdf_accept } from "@/utils/base/accept.js";
import { router_push_name } from "@/utils/server/router.js"
import { getfileTypeName } from "@/utils/server/file.js";
import templateList from "./templateList.vue";
const templateRef=ref()
const store = useStore();
const state = reactive({
  searchKey: {
    input: '',
  },//搜索框绑定值
})
let isOpenVisible = ref(false);
const visibleChange = (e) => {
  isOpenVisible.value = e;
};
const handleRemove = () => { };
const fileupload = (files) => {
  let temp = JSON.stringify(URL.createObjectURL(files.raw))
  // console.log('temp',temp)
  router_push_name('create_page', { files: temp })
};

const createtem = () => {
  router_push_name('create_page')
}
// const httpRequest = (rawFile) => {
//   let fileTypeName = getfileTypeName(rawFile.file.name);
//   let filetype = [
//     "pdf",
//     "PDF",
//     "docx",
//     "doc",
//     "DOCX",
//     "DOC",
//     "xls",
//     "xlsx",
//     "XLS",
//     "XLSX",
//     "word",
//     "WORD",
//     "txt",
//     "TXT",
//   ];
//   // console.log('文件上传rawFile11', rawFile, fileTypeName)
//   // uploadFile(rawFile.file, fileTypeName)
//   if (rawFile.file.size / 1024 / 1024 > 500) {
//     handleMessage("文档大小超过500MB");
//   } else if (filetype.includes(fileTypeName)) {
//     state.uploadType = 0;
//     loading.value = true;
//     // type:要转换的文件类型(0 word , 1 图片)
//     fileByPdf(0, [rawFile.file])
//       .then((res) => {
//         console.log(111, res, rawFile.file.name);
//         if (res.code == 200) {
//           getpdfByPic(rawFile.file.name, res.data.fileUrl)
//             .then((res2) => {
//               state.basefilesList[0].pages = res2.data;
//               unref(pagesNavigationRef).setCopies(res.data.length);
//               // 重新设置页码大小
//               unref(pagesNavigationRef).setTotalPages(
//                 state.basefilesList[state.basefilesIndex].pages.length
//               );
//               // state.uploaddiv = false;

//               loading.value = false;
//             })
//             .catch(() => {
//               loading.value = false;
//             });
//         } else {
//           loading.value = false;
//         }
//       })
//       .catch(() => {
//         loading.value = false;
//       });
//   } else {
//     handleMessage("请上传正确文档格式格式!");
//   }
// };


</script>

<style lang="scss" scoped>
@import "@/styles/color/value.scss";

.el-dropdown-link {
  padding: 8px 13px;
  border-radius: 5px;
  background-color: $active-theme;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: $color-button !important;

  .el-image {
    width: 16px;
    height: 16px;
  }
}

::v-deep .el-upload-list {
  display: none;
}

.sinputbox {
  ::v-deep .el-input__wrapper {
    box-shadow: none;
    border: 1px solid #dcdfe6;
    border-right: none;
  }

  ::v-deep .el-input-group__append {
    background-color: transparent;
    padding: 0 8px;
  }
}

.iconfont {
  color: #dcdfe6;
}</style>
